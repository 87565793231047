import { React, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Dropdown from './Dropdown';

export default function Navbar() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [overlayOn, setOverlayOn] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="flex h-20 w-full justify-center items-center g-white shadow-md fixed top-0 z-[1000] left-0 bg-[#04243d]">
            <div className="flex flex-row justify-center z-50">
                <div className='min-[1160px]:hidden block'>
                    {isMenuOpen ? 
                    <FontAwesomeIcon icon={faTimes} size="2x" onClick={() => setIsMenuOpen(!isMenuOpen)} />: 
                    <FontAwesomeIcon icon={faBars} size="2x" onClick={() => setIsMenuOpen(!isMenuOpen)}/>}
                </div>
                <ul className="min-[1160px]:flex min-[1px]:hidden flex-row gap-12 text-[18px]" style={{ fontFamily: "'Teachers', cursive" }}>
                    <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#bio")} >Bio</li>
                    <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#lbc")}>Leadership Baltimore</li>
                    <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#stemex")}>STEM Expo</li>
                    <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#wics")}>WICS Advisor</li>
                    <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#ghana")}>Ghana Trip</li>
                    <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#footer")}>Contact Me</li>
                    <li className="cursor-pointer hover:text-[gray]" onMouseOver={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>More <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown}/>
                    {isOpen && <Dropdown/>}</li>
                </ul>  
            
                {isMenuOpen && (
                overlayOn ? (
                    <div className="fixed top-20 inset-0 bg-black opacity-50 z-40 min-[1160px]:hidden"></div>
                ) : null
                )}


                <div className={`flex flex-col absolute min-[1160px]:hidden top-20 left-0 w-full bg-[#04243d] items-center gap-6 transform transition-transform z-50 ${isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"} `}
                style={{transition: "transform 0.3s ease, opacity 0.3s ease"}}
                >
                    <div className="overflow-y-auto h-[calc(100vh-80px)] w-full">
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#bio"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }} >Bio</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#lbc"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }} >Leadership Baltimore</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#stemex"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }}>STEM Expo</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#wics"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }}>WICS Advisor</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#ghana"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }}>Ghana Trip</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#npm"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }}>Summer GEMS/NPM</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#profdev"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }}>Prof Dev</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#me"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }}>Photos of Me</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#profdev"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }}>Brochure</li>
                        <li className="cursor-pointer hover:text-[white] list-none w-full text-center p-4 transition-all" onClick={() => {window.location.replace("/#footer"); setOverlayOn(!overlayOn); setIsMenuOpen(!isMenuOpen) }}>Contact Me</li>
                    </div>
                </div>
            </div>
        </div>
    );
}
