import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';


export default function Footer() {
  return (
    <div className='flex flex-col h-96 min-[884px]:flex-row min-[884px]:h-64 bg-[#04243d] text-[white] shadow-[rgba(6,_24,_44,_0.4)_0px_0px_0px_2px,_rgba(6,_24,_44,_0.65)_0px_4px_6px_-1px,_rgba(255,_255,_255,_0.08)_0px_1px_0px_inset] w-full items-center justify-between'>
        <div className="flex p-14 gap-5 flex-col" id="footer"> 
            <div className='flex gap-2.5 text-left'>
            <h1 className='text-3xl min-[370px]:text-4xl sm:text-5xl font-extralight' style={{ fontFamily: "'Kaushan Script', cursive" }}>KATRINA</h1>
            <h1 className='text-3xl min-[370px]:text-4xl sm:text-5xl font-medium' style={{ fontFamily: "'Kaushan Script', cursive" }}> ROBINSON</h1>
            </div>
            <h2 className='text-3xl font-extralight'>Contact Me:</h2>
            <p ><FontAwesomeIcon icon={faEnvelope} className="text-xl sm:text-2xl md:text-2xl" />  <a href="mailto:klynn.rob.kr@gmail.com" className='underline'>klynn.rob.kr@gmail.com</a></p>
            <p ><FontAwesomeIcon icon={faPhone} className="text-xl sm:text-2xl md:text-2xl" />   <a className='underline'>443-885-4407</a></p>
        </div>
        <div className='flex gap-6 p-11 text-[#F38A63]'>
            <a href="https://www.linkedin.com/in/katrina-robinson-9a925313b/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="text-3xl sm:text-4xl md:text-5xl" />
            </a>
            {/* <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="text-3xl sm:text-4xl md:text-5xl" />
            </a> */}
            <a href="https://www.facebook.com/profile.php?id=61566959698874" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} className="text-3xl sm:text-4xl md:text-5xl" />
            </a>
        </div>
  </div>
  )
}

