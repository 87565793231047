import {React, useEffect} from 'react';
import Slideshow from "./Slideshow";
import stockimg from "../Images/stockimg.jpg";
import npm1 from "../Images/npm1.jpg";
import npm2 from "../Images/npm2.jpg";
import npm3 from "../Images/npm3.jpg";
import AOS from "aos";
import 'aos/dist/aos.css';

export default function Npm() {
  const imgs = [npm1, npm2, npm3];

  useEffect(()=> {
    AOS.init({duration: 2000});
}, []);

  return (
    <div className='p-8 sm:px-16' id='npm'>
      <div data-aos="fade-up" className='flex flex-col gap-10 p-6 lg:gap-0 lg:flex-row-reverse items-center lg:items-start shadow-[0_8px_30px_rgb(255,255,255,0.12)] sm:p-10'>
            <div className='flex flex-col text-center lg:text-right justify-center items-center lg:justify-end lg:items-end gap-11 w-full lg:w-2/4'>
                <h1 className='text-4xl sm:text-5xl font-medium underline decoration-[#F38A63]'>Summer GEMS/Near Peer Mentors</h1>
                <p className='w-full sm:w-3/4 text-lg lg:text-xl tracking-wide'>
                The Near Peer Mentoring (NPMs) program at Morgan State University has been in existence
                since 2018-2019 in partnership with Walter Reed Army Institute of Research (WRAIR). The
                NPMs are biology majors pursuing a career in STEM. These college scholars train with scientists
                and educators from WRAIR to go into select Baltimore City Public School System to mentor and
                teach ninth grade biology students. Near Peer Mentors introduce inquiry-based STEM modules
                to the students, offer educational and career guidance, build supportive relationships with their
                mentee, and encourage students to pursue their goals.
                </p>
            </div>
            <div className="w-full lg:w-2/4">
                <Slideshow images={imgs} />
            </div>
      </div>
    </div>
  );
}
