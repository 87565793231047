import React from 'react'
import './App.css';
import Navbar from './Components/Navbar';
import Bio from './Components/Bio';
import StemExpo from './Components/StemExpo';
import Wics from './Components/Wics';
import Ghana from './Components/Ghana';
import Npm from './Components/Npm';
import ProfDev from './Components/ProfDev';
import Footer from './Components/Footer';
import Me from './Components/Me';
import Slecop from './Components/Slecop';
import Lbc from './Components/Lbc';


function App() {

  return (
    <div className='overflow-hidden bg-[#04243d] text-white'>
      <div className="flex flex-col min-h-screen min-[1118px]:gap-32 gap-20">
        <header className='sticky top-0 z-50 text-white'>
          <Navbar />
        </header>
        <main className="flex items-center justify-center flex-col gap-40" style={{ fontFamily: "'DM Sans', sans-serif" }}>
          <Bio />
          <Lbc />
          <StemExpo />
          <Wics />
          <Ghana />
          <Npm />
          <ProfDev />
          <Me />
          <Slecop />
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default App;

