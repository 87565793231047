import {React, useEffect} from 'react';
import Slideshow from "./Slideshow";
import stockimg from "../Images/stockimg.jpg";
import profdev1 from "../Images/profdev1.jpg";
import profdev2 from "../Images/profdev2.jpg";
import profdev3 from "../Images/profdev3.jpg";
import AOS from "aos";
import 'aos/dist/aos.css';

export default function ProfDev() {
  const imgs = [profdev1, profdev2, profdev3]


  useEffect(()=> {
    AOS.init({duration: 2000});
}, []);
  return (
    <div className='p-8 sm:px-16' id='profdev'>
      <div data-aos="fade-up" className='flex flex-col gap-10 p-6 lg:gap-0 lg:flex-row items-center lg:items-start shadow-[0_8px_30px_rgb(255,255,255,0.12)] sm:p-10'>
        <div className='flex flex-col justify-center items-center text-center lg:text-left lg:justify-start lg:items-start gap-11 w-full lg:w-2/4'>
          <h1 className='text-4xl sm:text-5xl font-medium underline decoration-[#F38A63]'>Professional Development</h1>
          <p className='w-full sm:w-3/4 text-lg lg:text-xl tracking-wide'>
          I actively engage with teachers, students, and faculty at Morgan State University through hands-on professional development workshops. 
          These photos capture it in action, facilitating STEM education sessions, fostering learning, and empowering educators and future leaders in the STEM field. 
          </p>
        </div>
        <div className="w-full lg:w-2/4">
          <Slideshow images={imgs} />
        </div>
      </div>
    </div>
  );
}
