import {React, useEffect} from 'react';
import Slideshow from "./Slideshow";
import stockimg from "../Images/stockimg.jpg";
import wics1 from "../Images/wics1.png"
import wics2 from "../Images/wics2.png"
import wics3 from "../Images/wics3.png"
import wics4 from "../Images/wics4.png"
import wics5 from "../Images/wics5.jpg"
import wics6 from "../Images/wics6.jpg"
import wics7 from "../Images/wics7.jpg"
import wics8 from "../Images/wics8.jpg"
import wics9 from "../Images/wics9.jpg"
import wics10 from "../Images/wics10.jpg"
import wics11 from "../Images/wics11.jpg"

import AOS from "aos";
import 'aos/dist/aos.css';

export default function Wics() {
  const imgs = [wics7,wics8,wics9,wics10, wics11, wics1,wics2,wics3,wics4,wics5,wics6]

  useEffect(()=> {
    AOS.init({duration: 2000});
}, []);

  return (
    <div className='p-8 sm:px-16' id='wics'>
      <div data-aos="fade-up" className='flex flex-col gap-10 p-6 lg:gap-0 lg:flex-row-reverse items-center lg:items-start shadow-[0_8px_30px_rgb(255,255,255,0.12)] sm:p-10'>
            <div className='flex flex-col text-center lg:text-right justify-center items-center lg:justify-end lg:items-end gap-11 w-full lg:w-2/4'>
                <h1 className='text-4xl sm:text-5xl font-medium underline decoration-[#F38A63]'>Morgan State Women in Computer Science (WICS)</h1>
                <p className='w-full sm:w-3/4 text-lg lg:text-xl tracking-wide'>
                I am proud to have served as the advisor for The Morgan State University Women in Computer Science. 
                The Morgan State University virtual Coding Camp program was established to introduce and
                inspire middle through high school students to the world of computer science. This coding camp,
                which teaches the Python Language, is to make aware of the wonderful possibilities to the
                underrepresented students (e.g., females, racial and ethnic minorities, English language learners
                (ELL), rural and disabled students). This four to six week Saturday-only program teaches the
                students a new skill every week. Students are encouraged to play online games that demonstrate
                their new knowledge. These virtual coding camps are taught by IT Leaders who are students in
                the computer science discipline.
                </p>
            </div>
            <div className="w-full lg:w-2/4">
                <Slideshow images={imgs} />
            </div>
      </div>
    </div>
  );
}
