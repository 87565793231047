import {React, useState, useEffect} from 'react';
import headshot from "../Images/Katrina headshot.jpg";
import headshot3 from "../Images/katrina circle design (1).png"
// import headshot2 from "../Images/katrina design.png"
import AOS from "aos";
import 'aos/dist/aos.css';

export default function Bio() {
    const [showMore, setShowMore] = useState(true)

    const handleShowMore = () => {
      setShowMore(!showMore)
    }

    useEffect(()=> {
        AOS.init({duration: 2000});
    }, []);
  return (
    <div className='pt-5 lg:pt-10 xl:pt-20'>
        <div className='flex flex-col min-[1118px]:flex-row items-center justify-center gap-7 min-[1450px]:gap-4 xl:gap-3 min-[1118px]:gap-20 min-h-[65vh]'>
            <img className="w-[28rem] h-[20rem] sm:w-[37rem] sm:h-[28rem] lg:w-[35rem] lg:h-[25rem] xl:w-[45rem] xl:h-[30rem] min-[1118px]:mt-[-150px] rounded-2xl object-cover" 
            data-aos="fade-right-up" 
            src={headshot3} 
            alt="Headshot"/>
            {/* <img className="w-[32rem] h-[32rem] rounded-full object-cover" src={headshot} alt="Headshot"/> */}
            <div className='flex flex-col gap-5 text-center items-center justify-center w-3/4 min-[1118px]:w-1/4 min-[1118px]:text-left min-[1118px]:items-start' data-aos="slide-up">
                <h1 className='text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extralight text-white' style={{ fontFamily: "'Kaushan Script', cursive" }}>KATRINA</h1>
                <h1 className='text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-medium ml-[-8px] text-white' style={{ fontFamily: "'Kaushan Script', cursive" }}>ROBINSON</h1>
                <p className='text-[18px] w-[90%] min-[1118px]:w-full'>I am a Professional Development Manager at the Center for Excellence in Mathematics and Science Education (CEMSE) at 
                Morgan State University. I lead STEM education workshops for teachers, students, and faculty, and organizes the annual Morgan State University STEM Expo, connecting 
                students with STEM career opportunities. 
               </p>
                <button type="button" class="text-[white] text-1xl hidden min-[1118px]:block bg-[#C8E4EE] hover:bg-[#E6F0F3] focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-[#F38A63] dark:hover:bg-[#F16B3A] focus:outline-none dark:focus:ring-blue-800 w-2/4" onClick={() => window.location.replace("/#bio")}>About Me</button>
            </div>
        </div>
        <div className='flex flex-col items-center gap-2.5 text-left relative top-20' data-aos="fade-right-up" id="bio">
          <h1 className='text-5xl font-medium underline decoration-[#F38A63]'>About</h1>
          {showMore ? ( <> <p className='w-3/4 text-[18px] text-center min-[1118px]:w-2/4' >Ms. Katrina Robinson is the Professional Development Manager in the Center for Excellence in
            Mathematics and Science Education (CEMSE). In this capacity, she works with In-Service and
            Pre-Service teachers, faculty, and students conducting STEM education workshops. Also, Ms.
            Robinson organizes the annual Morgan State University STEM Expo that gives opportunities for
            students K-16 to explore and aspire to the vast and diverse number of STEM professions! The
            STEM Expo also allows connections with various stakeholders in the Baltimore community and
            beyond. This allows better STEM education and job and career opportunities in the region. 
          </p>
          <p onClick={handleShowMore} className='underline font-medium cursor-pointer'>SHOW MORE</p></> ) : (
          <><p className='w-3/4 text-[18px] text-center min-[1118px]:w-2/4'>Ms. Katrina Robinson is the Professional Development Manager in the Center for Excellence in
          Mathematics and Science Education (CEMSE). In this capacity, she works with In-Service and
          Pre-Service teachers, faculty, and students conducting STEM education workshops. Also, Ms.
          Robinson organizes the annual Morgan State University STEM Expo that gives opportunities for
          students K-16 to explore and aspire to the vast and diverse number of STEM professions! The
          STEM Expo also allows connections with various stakeholders in the Baltimore community and
          beyond. This allows better STEM education and job and career opportunities in the region.
          CEMSE has been designated as the East Coast Hub by the Department of Defense (DoDSTEM)
          for the Defense STEM Education Consortium (DSEC), an organization whose goal is to
          strengthen STEM education and STEM outreach programs in kindergarten through college.<br></br><br></br>
          
          As the East Coast Hub lead for Washington, D.C.; Maryland and Northern Virginia (DMV), Ms.
          Robinson works with underserved, underrepresented, Title I and military connected students. In
          this capacity, she provides professional development for teachers, academic enrichment for K–12
          students and internships for undergraduate students in the DMV.  Ms. Robinson is also the
          advisor for the organization Women in Computer Science (WICS) where she was awarded the
          “2023 Advisor of the Year Award” by the Office of Student Life and Development at Morgan
          State University.<br></br><br></br>
          Katrina Robinson holds a Master of Science degree in Natural and Applied Sciences, a Bachelor
          of Science degree in Elementary Education and a certification with the Maryland Space Grant
          Consortium. She also holds international and national certifications in STEM professional
          development. She served as an Aerospace Education Specialist with the National Aeronautics
          and Space Administration (NASA).
          In her free time, Katrina Robinson loves to travel nationally and internationally with her
          husband, Frank.</p>
          <p onClick={handleShowMore} className='underline font-medium cursor-pointer'>SHOW LESS</p>
          </> ) }
        </div>
    </div>
  )
}
