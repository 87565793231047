import {React, useState} from 'react'

export default function Dropdown() {

  return (
    <div className='absolute top-14 bg-[#04243d] text-white z-50 shadow-[rgba(255,_255,_255,_0.2)_0px_10px_30px_-7px]'>
      <ul className='p-5'>
        <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#npm")}>Summer GEMS/NPM</li>
        <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#profdev")}>Prof Dev</li>
        <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#me")}>Photos of Me</li>
        <li className="cursor-pointer hover:text-[gray]" onClick={() => window.location.replace("/#brochure")}>Brochure</li>
      </ul>
    </div>
  )
}

