import {React, useEffect} from 'react';
import Slideshow from "./Slideshow";
import stockimg from "../Images/stockimg.jpg";
import brochure from "../Images/borchure.jpg";
import AOS from "aos";
import 'aos/dist/aos.css';

export default function Slecop() {
//   const imgs = [me1, me2];

  useEffect(()=> {
    AOS.init({duration: 2000});
}, []);

  return (
    <div className='p-8 sm:px-16' id='brochure'>
      <div data-aos="fade-up" className='flex flex-col gap-10 p-6 lg:gap-0 lg:flex-row-reverse items-center lg:items-start shadow-[0_8px_30px_rgb(255,255,255,0.12)] sm:p-10'>
            <div className='flex flex-col text-center lg:text-right justify-center items-center lg:justify-end lg:items-end gap-11 w-full lg:w-2/4'>
                <h1 className='text-4xl sm:text-5xl font-medium underline decoration-[#F38A63]'>Photo Featured on STEM Learning Ecosystems Brochure!</h1>
                <p className='w-full sm:w-3/4 text-lg lg:text-xl tracking-wide'>
                I am honored to have my photo included in the international brochure for the annual convening of the STEM Learning Ecosystems Community of Practice (SLECoP). 
                The conference will be held from October 29-31, 2024 in Chicago, IL. This initiative of 111+ global communities work together to improve STEM across the world. 
                The SLECoP has been a nationally recognized force for STEM and STEM equity. With 111+ Ecosystems, serving an estimated 40 million students, the SLECoP brings 
                together a cross-sector of partners that work all together to improve STEM opportunities, especially for underserved students and communities of color.

                When I attend this conference later this month, I will be joining the 700 + leaders, stakeholders, and decision-makers who represent pre-K education; business 
                and industry; postsecondary institutions; non-profit and community based organizations; STEM-rich and cultural institutions and government agencies. Everyone 
                will come together to learn and share best practices in equitable STEM education to build a robust talent pipeline and empower the development of a diverse and 
                inclusive workforce.
                </p>
            </div>
            <div className="w-full lg:w-2/4">
                <img src={brochure}/>
                {/* <Slideshow images={imgs} /> */}
            </div>
      </div>
    </div>
  );
}
