import {React, useEffect} from 'react';
import Slideshow from "./Slideshow";
import stockimg from "../Images/stockimg.jpg";
import AOS from "aos";
import lbc1 from "../Images/lbc1.jpeg"
import 'aos/dist/aos.css';

function Lbc() {
    const imgs = [lbc1]

    useEffect(()=> {
        AOS.init({duration: 2000});
    }, []);

  return (
    <div className='p-8 sm:px-16' id='lbc'>
    <div data-aos="fade-up" className='flex flex-col gap-10 p-6 lg:gap-0 lg:flex-row-reverse items-center lg:items-start shadow-[0_8px_30px_rgb(255,255,255,0.12)] sm:p-10'>
          <div className='flex flex-col text-center lg:text-right justify-center items-center lg:justify-end lg:items-end gap-11 w-full lg:w-2/4'>
              <h1 className='text-4xl sm:text-5xl font-medium underline decoration-[#F38A63]'>Leadership Baltimore County</h1>
              <p className='w-full sm:w-3/4 text-lg lg:text-xl tracking-wide'>
              I am happy to announce that I have been accepted into the esteemed 2025 Leadership Baltimore County! This organization has been preparing individuals for leadership
               positions for 35 years. The 10-month program will include approximately 40 high achievers to enhance their organizational effectiveness and become a positive change
                in their workplace. I am excited to start this new venture!
              </p>
          </div>
          <div className="w-full lg:w-2/4">
              <Slideshow images={imgs} />
          </div>
    </div>
  </div>
  )
}

export default Lbc
