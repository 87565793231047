import {React, useEffect} from 'react';
import Slideshow from "./Slideshow";
import stockimg from "../Images/stockimg.jpg";
import me1 from "../Images/me1.jpg";
import me2 from "../Images/me2.jpg";
import AOS from "aos";
import 'aos/dist/aos.css';

export default function Me() {
  const imgs = [me1, me2];

  useEffect(()=> {
    AOS.init({duration: 2000});
}, []);

  return (
    <div className='p-8 sm:px-16' id='me'>
      <div data-aos="fade-up" className='flex flex-col gap-10 p-6 lg:gap-0 lg:flex-row-reverse items-center lg:items-start shadow-[0_8px_30px_rgb(255,255,255,0.12)] sm:p-10'>
            <div className='flex flex-col text-center lg:text-right justify-center items-center lg:justify-end lg:items-end gap-11 w-full lg:w-2/4'>
                <h1 className='text-4xl sm:text-5xl font-medium underline decoration-[#F38A63]'>Photos of Me</h1>
                <p className='w-full sm:w-3/4 text-lg lg:text-xl tracking-wide'>
                Here are a few photos of me, showcasing different moments and experiences from my personal and professional journey. I’ve included them to offer you a glimpse into my
                world, allowing you to see the person behind the work. Each photo represents a memory
                that holds significant meaning. I believe these images capture not just my professional endeavors but also the essence of who I am—my values, interests, and the people and
                places that inspire me. I hope these photos give you a better sense of who I am.
                </p>
            </div>
            <div className="w-full lg:w-2/4">
                <Slideshow images={imgs} />
            </div>
      </div>
    </div>
  );
}
