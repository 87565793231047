import { useEffect, useState } from 'react';

const Slideshow = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
    return () => {
      clearInterval(intervalId);
    };
  }, [images.length]);

  return (
    <div className="relative w-full max-w-[800px] mx-auto">
      <img src={images[currentImageIndex]} alt="Slider" className="w-full h-[300px] md:h-[400px] lg:h-[500px] object-cover object-center shadow-md" />
      <div className="absolute bottom-0 left-0 right-0 flex justify-center mb-4">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => goToImage(index)}
            className={`w-3 h-3 mx-1 rounded-full ${index === currentImageIndex ? 'bg-[#020617]' : 'bg-white/80'}`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
