import {React, useEffect} from 'react';
import Slideshow from "./Slideshow";
import ghana1 from "../Images/ghana1.jpg";
import ghana2 from "../Images/ghana2.jpg";
import ghana3 from "../Images/ghana3.jpg";
import ghana4 from "../Images/ghana4.jpg";
import ghana5 from "../Images/ghana5.jpg";
import ghana6 from "../Images/ghana6.jpg";
import ghana7 from "../Images/ghana7.jpg";
import ghana8 from "../Images/ghana8.jpg";
import ghana9 from "../Images/ghana9.jpg";
import ghana10 from "../Images/ghana10.jpg";
import ghana11 from "../Images/ghana11.jpg";
import ghana12 from "../Images/ghana12.jpg";
import ghana13 from "../Images/ghana13.jpg";
import AOS from "aos";
import 'aos/dist/aos.css';

export default function Ghana() {
  const imgs = [ ghana5, ghana6, ghana7, ghana1, ghana2, ghana3, ghana4, ghana8, ghana9, ghana10, ghana11, ghana12, ghana13]

  useEffect(()=> {
    AOS.init({duration: 2000});
}, []);

  return (
    <div className='p-8 sm:px-16' id='ghana'>
      <div data-aos="fade-up" className='flex flex-col gap-10 p-6 lg:gap-0 lg:flex-row items-center lg:items-start shadow-[0_8px_30px_rgb(255,255,255,0.12)] sm:p-10'> 
        <div className='flex flex-col text-center lg:text-left justify-center items-center lg:justify-start lg:items-start gap-11 w-full lg:w-2/4'>
          <h1 className='text-4xl sm:text-5xl font-medium underline decoration-[#F38A63]'>Ghana Study Abroad</h1>
          <p className='w-full sm:w-3/4 text-lg lg:text-xl tracking-wide'>
          From June 1-16, 2024, I led a group of "Black Women in STEM" students from Morgan State University to Accra, Ghana, where we conducted STEM workshops for 6-12th
          grade students. The Ghanaian students built Bluetooth speakers, made lip balm and gloss, and created polymers with endothermic and exothermic reactions. 

          I also led professional development sessions for teachers, including a drone training program covering Four Forces of Flight, regulations, and safety protocols. 
          Students constructed and flew paper and balsa wood airplanes, and learned about aerodynamics. Our success has led to an invitation to return
          in summer 2025 for more STEM programs!
          </p>
        </div>
        <div className="w-full lg:w-2/4">
          <Slideshow images={imgs} />
        </div>
      </div>
    </div>
  );
}
